<template>
  <div class="w-100 mt-2">
    <div class="mb-2" v-for="product in filteredProducts" :key="product.id">
      <div
        class="d-flex cursor-pointer"
        :style="
          getMarginCss(imageCompStyles.style, isDesktopView) +
          'justify-content:' +
          imageCompStyles.style.position +
          ';'
        "
       @click="openProduct(product)"
      >
        <LazyImage
          :src="product.banner_image ? getBannerImageSrc(product) : ''"
          :key="getBannerImageSrc(product)"
          
          :style="getImageStyles"
          :title="`Custom Inline Image`"
          :alt="`Custom Inline Image`"
          class="Img-Fluid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cssFunctionMixin from "../../customize/mixin/cssFunction";
export default {
  mixins: [cssFunctionMixin],
  props:  ['pro', "products", "imageCompStyles", "isDesktopView", "displayProductsLength"],
  methods: {
    openProduct(product){
      const url = product.detail_link
      if (url) {
        const pattern = /^(http|https|ftp):\/\//;
        const formattedUrl = pattern.test(url) ? url : `https://${url}`;
        const openUrl = (url) => {
          window.top.location.href = url;
        };
        const newTab = window.open(formattedUrl, "_blank");
        if (!newTab || newTab.closed || typeof newTab.closed == "undefined") {
          openUrl(formattedUrl);
        }
      }
    },
    getBannerImageSrc(product) {
      if (this.isDesktopView) {
        return product.banner_image.desktop_image;
      } else {
        return product.banner_image.mobile_image !== ""
          ? product.banner_image.mobile_image
          : product.banner_image.desktop_image;
      }
    },
  },
  computed: {
    limitProduct(){
        return this.displayProductsLength(this.pro)
    },
    filteredProducts() {
    return this.products
      .filter((product) => product?.banner_image)
      .slice(0, this.limitProduct);
  },
    getImageStyles() {
      const {
        backgroundColor,
        backgroundSize,
        borderColor,
        position,
        borderRadius,
        borderSize,
        desktopHeight,
        desktopWidth,
        mobileWidth,
        mobileHeight,
      } = this.imageCompStyles.style;

      const width =
        position == "fullWidth"
          ? `width:100%;`
          : this.isDesktopView
          ? `width:${desktopWidth}px;`
          : ` width:${mobileWidth}px;`;
      const height = this.isDesktopView
        ? `height:${desktopHeight}px;`
        : `height:${mobileHeight}px;`;
      return (
        `border-radius:${borderRadius}px;border:${borderSize}px solid ${borderColor};background-color:${backgroundColor};object-fit:${backgroundSize};` +
        width +
        height
      );
    },
  },
};
</script>
